/* eslint-disable react-hooks/rules-of-hooks */
import { Story_Play_Type } from '@/baseType';
import { useInteractiveStoryGenresQuery } from '@/operations/queries/home/__generated__/useInteractiveStoryGenreList.generated';
import { useEpubStoryGenresQuery } from '@/operations/queries/home/__generated__/useNovelStoryGenreList.generated';
import { useStoryGenreListQuery } from '@/operations/queries/home/__generated__/useStoryGenreList.generated';

export const useGenreList = (playType?: string) => {
  switch (playType) {
    case Story_Play_Type.Epub: {
      const { data: epubData } = useEpubStoryGenresQuery();
      return epubData?.epubStoryGenresV3;
    }
    case Story_Play_Type.Interactive: {
      const { data: interactiveData } = useInteractiveStoryGenresQuery();
      return interactiveData?.interactiveStoryGenresV3;
    }

    default: {
      const { data: homeData } = useStoryGenreListQuery();
      return homeData?.homeStoryGenresV3;
    }
  }
};
