import React from 'react';
import { useRouter } from 'next/router';
import { WeekDayTab } from '@customTypes/story';
import { getWeekDaySectionName } from '@common/utils';

import StoryItemPlaceHolder from '@components/commons/loading/StoryItemPlaceHolder';
import StoryGrid from '../../../common/StoryGrid';

const WeekDayStorySkeletonGrid = () => {
  const router = useRouter();
  const { week } = router.query;

  return (
    <StoryGrid section={getWeekDaySectionName(week as WeekDayTab)}>
      {new Array(6).fill('').map((_, index) => (
        <StoryItemPlaceHolder key={index} />
      ))}
    </StoryGrid>
  );
};

export default WeekDayStorySkeletonGrid;
