import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { CheckBottomSheet } from 'public/assets';
import { STORAGE_KEY, HOME_TAB_SCROLL_POSITION } from '@common/values';
import { Story_Play_Type } from '@/baseType';
import { useGenreList } from '@/hooks/useGenreList';

interface IGenreList {
  onClose: () => void;
  contentHeight: number;
}

const GenreList = ({ onClose, contentHeight }: IGenreList) => {
  const { t } = useTranslation();
  const router = useRouter();
  // const [genreList, setGenreList] = useState<Genre[]>([]);
  const { genre = 'TOTAL' } = router.query;
  const playType = router.pathname.includes('/webnovel')
    ? Story_Play_Type.Epub
    : router.pathname.includes('/interactive')
    ? Story_Play_Type.Interactive
    : undefined;

  const genreList: any = useGenreList(playType);

  const handelGenreItemClick = (selectedGenreType: string) => {
    onClose();
    sessionStorage.setItem(
      STORAGE_KEY.HOME.SCROLL_POSITION,
      `${HOME_TAB_SCROLL_POSITION}`,
    );

    router.push({
      pathname: window.location.pathname,
      query: { ...router.query, genre: selectedGenreType },
    });
  };

  return (
    <div css={container({ contentHeight })}>
      {genreList &&
        [
          {
            showFilter: true,
            type: 'TOTAL',
            text: {
              ko_KR: '전체',
              key: 'ugc_screen_label_all',
            },
          },
          ...genreList,
        ]?.map((item: any) => (
          <li
            key={item.type}
            className="genre-item"
            onClick={() => handelGenreItemClick(item.type)}
          >
            <div>{t(item.text.key ?? '')}</div>
            {genre === item.type && <CheckBottomSheet />}
          </li>
        ))}
    </div>
  );
};

const container = ({ contentHeight }: { contentHeight: number }) => css`
  max-height: ${contentHeight}px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  .genre-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3.5rem;
    color: ${theme.colors.gray700};
    cursor: pointer;
    ${theme.font.body.body3}
  }
`;

export default GenreList;
