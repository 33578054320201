import { useRouter } from 'next/router';
import { gql } from '@apollo/client';
import {
  useListWeekdayStoryQuery as useQuery,
  ListWeekdayStoryQueryVariables,
} from '../home/__generated__/useWeekDayStory.generated';

export const WEEKDAY_STORY_ITEM = gql`
  fragment WeekdayStoryItem on Story {
    storyId
    name
    type
    onHiatus
    isFinished
    isOriginal
    isNew
    isUp
    isAdult
    mainImageFile {
      link
    }
    introImageFile {
      link
    }
    storyWeekdays {
      weekday
    }
    genreKey {
      text {
        key
      }
    }
    authorName
    isTimeLeapFree
    shortDesc
    sectionType
    playType
  }
`;

export const LIST_WEEKDAY_STORY = gql`
  query listWeekdayStory(
    $page: Int
    $pageSize: Int
    $uiSectionType: UI_STORY_SECTION_TYPE
    $sorting: STORY_SORTING
    $weekday: STORY_WEEKDAY
    $genre: String
    $version: Int
    $playType: STORY_PLAY_TYPE
  ) {
    listStoryByWebV3(
      data: {
        page: $page
        pageSize: $pageSize
        uiSectionType: $uiSectionType
        sorting: $sorting
        weekday: $weekday
        genre: $genre
        version: $version
        playType: $playType
      }
    ) {
      totalCount
      page
      pageSize
      version
      list {
        ...WeekdayStoryItem
      }
    }
  }
  ${WEEKDAY_STORY_ITEM}
`;

export const useWeekDayStoryQuery = (
  variables: ListWeekdayStoryQueryVariables,
) => {
  const router = useRouter();
  const { data, fetchMore, loading, networkStatus } = useQuery({
    variables,
    onError: (e) => {
      router.push('/404');
    },
  });

  const hasNext =
    data &&
    data?.listStoryByWebV3.totalCount > data?.listStoryByWebV3.list.length;

  return { data, fetchMore, hasNext, loading, networkStatus };
};
