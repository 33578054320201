import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';

import BottomSheetContainer from '@components/commons/popup/container/BottomSheetContainer';
import GenreList from './GenreList';

interface IGenreSelectionBottomSheet {
  isShow: boolean;
  onClose: () => void;
}

const GenreSelectionBottomSheet = ({
  isShow,
  onClose,
}: IGenreSelectionBottomSheet) => {
  const { t } = useTranslation();
  const [isCloseButtonClick, setIsCloseButtonClick] = useState(false);

  const handleCloseButtonClick = () => {
    setIsCloseButtonClick(true);

    setTimeout(() => {
      setIsCloseButtonClick(false);
      onClose();
    }, 450);
  };

  if (!isShow) return null;

  return (
    <BottomSheetContainer
      title={t('common_popup_title_select_genre')}
      isTitleHasUnderline={true}
      isCloseButtonClick={isCloseButtonClick}
      onClose={handleCloseButtonClick}
      marginTop={44}
      contentHeight={window.innerHeight - 44}
      hasCloseButton
    >
      <ul css={container}>
        <GenreList
          onClose={handleCloseButtonClick}
          contentHeight={window.innerHeight - 44}
        />
      </ul>
    </BottomSheetContainer>
  );
};

const container = css`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default GenreSelectionBottomSheet;
