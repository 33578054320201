import { gql } from '@apollo/client';
import {
  useListStoryForEpubContestWinnerQuery as useQuery,
  ListStoryForEpubContestWinnerQueryVariables,
} from './__generated__/useUGCContestWinnerStoryList.generated';

export const UGC_CONTEST_WINNER_STORY = gql`
  query listStoryForEpubContestWinner($page: Int, $version: Int) {
    listStoryForEpubContestWinner(
      data: { page: $page, pageSize: 15, version: $version }
    ) {
      totalCount
      page
      pageSize
      version
      list {
        ...StoryVerticalCoverDto
      }
    }
  }
`;
export const useWebNovelContestWinnerStoryList = (
  variables?: ListStoryForEpubContestWinnerQueryVariables,
) => {
  const { data, fetchMore, loading } = useQuery({
    variables,
  });

  const hasNext =
    data &&
    data?.listStoryForEpubContestWinner.totalCount >
      data?.listStoryForEpubContestWinner.list.length;

  return { data, fetchMore, hasNext, loading };
};
