import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { usePlayTypeContext } from '@/provider/PlayTypeProvider';
import { formatMoreLink, getHomeSectionName } from '@common/utils';

import StoryItem from '@components/main/common/storyItem/StoryItem';
import StoryWrapper from '@components/main/common/StoryWrapper';
import SectionTitle from '@components/main/common/title/SectionTitle';
import { CarouselStory } from '@components/commons';
import { useWebNovelContestWinnerStoryList } from '@/operations/queries/home/useUGCContestWinnerStoryList';

const UGCContestWinnerStory = () => {
  const { t } = useTranslation();
  const { playType } = usePlayTypeContext();

  const { data } = useWebNovelContestWinnerStoryList();

  if (!data?.listStoryForEpubContestWinner.list.length) {
    return null;
  }

  return (
    <StoryWrapper>
      <SectionTitle
        title={t('home_screen_label_section_wwwnovel')}
        showMoreButton
        moreLink={formatMoreLink({
          sectionName: 'ugc',
          playType,
        })}
      />
      <CarouselStory
        section={getHomeSectionName({
          playType,
          section: t('home_screen_label_section_wwwnovel'),
        })}
      >
        {data.listStoryForEpubContestWinner.list?.map((item, index, array) => (
          <StoryItem
            index={index}
            section={getHomeSectionName({
              playType,
              section: t('home_screen_label_section_wwwnovel'),
            })}
            key={item.storyId}
            data={item}
            isFirstItem={index === 0}
            isLastItem={index === array.length - 1}
            showInteractiveBanner={item.playType === 'Interactive' && !playType}
          />
        ))}
      </CarouselStory>
    </StoryWrapper>
  );
};

export default UGCContestWinnerStory;
