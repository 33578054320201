import React, { memo } from 'react';
import { BannerDtoFragment } from '@operations/queries/home/__generated__/useGetAllBanners.generated';

import { RedirectUrlToStoryDetailRow } from '@components/main/common/banner/RedirectUrlToStoryDetailRow';
import EmptyHomeBannerItem from './EmptyHomeBannerItem';
import HomeBannerItem from './HomeBannerItem';
import { CarouselTopBanner } from '@components/commons';

interface IHomeBanner {
  list?: BannerDtoFragment[];
}

const HomeBanner = ({ list }: IHomeBanner) => {
  return (
    <div style={{ position: 'relative' }}>
      <RedirectUrlToStoryDetailRow />
      <CarouselTopBanner>
        {list?.length ? (
          list?.map((story, index) => (
            <HomeBannerItem
              section={'홈_배너'}
              data={story}
              index={index}
              key={story.storyId}
            />
          ))
        ) : (
          <EmptyHomeBannerItem />
        )}
      </CarouselTopBanner>
    </div>
  );
};

export default memo(HomeBanner);
