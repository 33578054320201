import { gql } from '@apollo/client';
import {
  useGetDirectAdsBannerQuery as useQuery,
  GetDirectAdsBannerQueryVariables,
} from './__generated__/useDirectAdsBanner.generated';

export const DIRECT_ADS_BANNER = gql`
  query GetDirectAdsBanner($type: DIRECT_ADS_TYPE!) {
    getDirectAds(data: { type: $type }) {
      imageFile {
        link
      }
      link
      aOSFallbackLink
      iOSFallbackLink
      title
      directAdsId
    }
  }
`;

export const useDirectAdsBanner = (
  variables: GetDirectAdsBannerQueryVariables,
) => {
  const { data, loading } = useQuery({ variables });

  return { data, loading };
};
