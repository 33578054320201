import React, { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { BannerDtoFragment } from '@operations/queries/home/__generated__/useGetAllBanners.generated';
import {
  viewStoryThumbnail,
  viewHomeTopBanner,
  clickHomeTopBanner,
  viewInteractiveTopBanner,
  viewNovelTopBanner,
  clickInteractiveTopBanner,
  clickNovelTopBanner,
} from '@lib/eventManager';
import { Section, ContentType } from '@customTypes/event';

import { Banner_Link_Type, Story_Play_Type } from '@/baseType';
import { openOldDeepLink } from '@/common/utils';

import SPImage from '@components/commons/image/SPImage';

interface IHomeBannerItem {
  data: BannerDtoFragment;
  index: number;
  section: Section;
}

const HomeBannerItem = ({ data, index, section }: IHomeBannerItem) => {
  const storyRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const { t } = useTranslation();
  const isHome = !(
    router.pathname.includes('/interactive') ||
    router.pathname.includes('/webnovel')
  );

  useEffect(() => {
    if (!storyRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            storyRef.current &&
            entry.target instanceof HTMLElement
          ) {
            viewStoryThumbnail({
              section,
              index: entry.target.dataset.index,
              story: entry.target.dataset.name,
              storyId: entry.target.dataset.id,
              contentType: entry.target.dataset.contentType as ContentType,
            });

            isHome &&
              viewHomeTopBanner({
                bannerId: +(entry.target.dataset.bundleBannerId as string),
                bannerType: entry.target.dataset.name as string,
              });

            router.pathname.includes('/interactive') &&
              viewInteractiveTopBanner({
                bannerId: +(entry.target.dataset.bundleBannerId as string),
                bannerType: entry.target.dataset.name as string,
              });

            router.pathname.includes('/webnovel') &&
              viewNovelTopBanner({
                bannerId: +(entry.target.dataset.bundleBannerId as string),
                bannerType: entry.target.dataset.name as string,
              });
          }
        });
      },
      {
        threshold: 1,
      },
    );

    // 스토리 아이템 각각에 옵저버 부착
    observer.observe(storyRef.current as Element);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleBannerItemClick = () => {
    isHome &&
      clickHomeTopBanner({
        bannerId: data?.bundleBannerId,
        bannerType: data?.title as string,
      });

    router.pathname.includes('/interactive') &&
      clickInteractiveTopBanner({
        bannerId: data?.bundleBannerId,
        bannerType: data?.title as string,
      });

    router.pathname.includes('/webnovel') &&
      clickNovelTopBanner({
        bannerId: data?.bundleBannerId,
        bannerType: data?.title as string,
      });

    if (!data.linkType) {
      data.storyId && router.push(`/story/${data.storyId}`);
      return;
    }

    if (data.linkType === Banner_Link_Type.DeepLink) {
      openOldDeepLink({
        t,
        router,
        deepLink: data.link || '/',
        iosStoreLink: data.iOSFallbackLink || '/',
        aosStoreLink: data.aOSFallbackLink || '/',
      });
      return;
    }

    if (data.linkType === Banner_Link_Type.WebCurrentWindowLink) {
      router.push(data.link || '/');
      return;
    }

    if (data.linkType === Banner_Link_Type.WebNewWindowLink) {
      window.open(data.link || '/', '_blank');
      return;
    }
  };

  return (
    <div
      ref={storyRef}
      css={container({ isHome })}
      data-name={data.story?.name}
      data-index={index}
      data-id={data.storyId}
      data-banner-id={data.bundleBannerId}
      data-content-type={
        data.story?.playType === Story_Play_Type.Interactive
          ? 'interactive'
          : 'novel'
      }
      onClick={handleBannerItemClick}
    >
      <SPImage
        src={data.bannerImageFile?.link || ''}
        objectFit="cover"
        layout="fill"
        alt={data.content || ''}
      />
      <div className="dim-top" />
      <div className="dim-bottom" />
      <div className="story-title-wrapper">
        <h1 className="title">{data.title}</h1>
        <h3 className="desc">{data.content}</h3>
      </div>
    </div>
  );
};

const container = ({ isHome }: { isHome: boolean }) => css`
  position: relative;
  padding-bottom: ${isHome ? '70%' : '56.25%'};

  .story-title-wrapper {
    padding: 0 1em;
    width: 100%;
    position: absolute;
    bottom: 1.5625rem;

    .title {
      ${theme.font.header.headline2};
      color: ${theme.colors.white};
    }

    .desc {
      margin: 0.125rem 0 0.5rem;
      ${theme.font.body.body2};
      color: ${theme.colors.white};
    }

    @media screen and (max-width: ${theme.devices.mobile}) {
      bottom: 2.75em;
    }
  }

  .dim-top {
    position: absolute;
    width: 100%;
    height: 6.25rem;
    background: linear-gradient(
      180deg,
      rgba(36, 37, 38, 0) 0%,
      rgba(36, 37, 38, 0.3) 100%
    );
    transform: rotate(-180deg);
  }

  .dim-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10rem;
    background: linear-gradient(
      180deg,
      rgba(36, 37, 38, 0) 0%,
      rgba(36, 37, 38, 0.6) 100%
    );
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    padding-bottom: ${isHome ? '100%' : '56.25%'};
    // 56.25%: 이미지 비율값(9 / 16 * 100)
  }
`;

export default HomeBannerItem;
