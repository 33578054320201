import React, { memo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { viewHomeTab } from '@lib/eventManager';
import { useGetAllBundleBannersQuery } from '@/operations/queries/home/__generated__/useGetAllBanners.generated';
import { Banner_Location } from '@/baseType';

import HomeBanner from './HomeBanner';

const HomeBannerWrapper = () => {
  const [bannerList, setBannerList] = useState<any[]>([]);
  const router = useRouter();
  const { firstBannerStoryId } = router.query;

  useGetAllBundleBannersQuery({
    variables: {
      location: router.pathname.includes('/interactive')
        ? Banner_Location.Interactive
        : router.pathname.includes('/webnovel')
        ? Banner_Location.Epub
        : Banner_Location.Home,
    },
    onCompleted: (data) => {
      setBannerList(data.getAllBundleBannersByWeb);
    },
  });

  useEffect(() => {
    viewHomeTab();
    if (firstBannerStoryId) {
      const firstBanner = bannerList.filter(
        (story) => story.storyId === firstBannerStoryId,
      );
      const otherBanners = bannerList.filter(
        (story) => story.storyId !== firstBannerStoryId,
      );
      setBannerList([...firstBanner, ...otherBanners]);
    }
  }, [firstBannerStoryId]);

  return <HomeBanner list={bannerList} />;
};

export default memo(HomeBannerWrapper);
