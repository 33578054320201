import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDirectAdsBannerQueryVariables = Types.Exact<{
  type: Types.Direct_Ads_Type;
}>;


export type GetDirectAdsBannerQuery = { __typename?: 'Query', getDirectAds?: { __typename?: 'DirectAds', link?: string | null, aOSFallbackLink?: string | null, iOSFallbackLink?: string | null, title: string, directAdsId: number, imageFile: { __typename?: 'ImageFile', link: string } } | null };


export const GetDirectAdsBannerDocument = gql`
    query GetDirectAdsBanner($type: DIRECT_ADS_TYPE!) {
  getDirectAds(data: {type: $type}) {
    imageFile {
      link
    }
    link
    aOSFallbackLink
    iOSFallbackLink
    title
    directAdsId
  }
}
    `;

/**
 * __useGetDirectAdsBannerQuery__
 *
 * To run a query within a React component, call `useGetDirectAdsBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectAdsBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectAdsBannerQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetDirectAdsBannerQuery(baseOptions: Apollo.QueryHookOptions<GetDirectAdsBannerQuery, GetDirectAdsBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDirectAdsBannerQuery, GetDirectAdsBannerQueryVariables>(GetDirectAdsBannerDocument, options);
      }
export function useGetDirectAdsBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDirectAdsBannerQuery, GetDirectAdsBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDirectAdsBannerQuery, GetDirectAdsBannerQueryVariables>(GetDirectAdsBannerDocument, options);
        }
export type GetDirectAdsBannerQueryHookResult = ReturnType<typeof useGetDirectAdsBannerQuery>;
export type GetDirectAdsBannerLazyQueryHookResult = ReturnType<typeof useGetDirectAdsBannerLazyQuery>;
export type GetDirectAdsBannerQueryResult = Apollo.QueryResult<GetDirectAdsBannerQuery, GetDirectAdsBannerQueryVariables>;