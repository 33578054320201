import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { Story_Sorting } from '@/baseType';
import { STORAGE_KEY, HOME_TAB_SCROLL_POSITION } from '@common/values';

const SortingSelectionWrapper = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { sorting = Story_Sorting.DailyRankAsc } = router.query;
  const handleSortingItemClick = (selectedSortingType: Story_Sorting) => {
    sessionStorage.setItem(
      STORAGE_KEY.HOME.SCROLL_POSITION,
      `${HOME_TAB_SCROLL_POSITION}`,
    );
    router.push({
      pathname: window.location.pathname,
      query: {
        ...router.query,
        sorting: selectedSortingType,
      },
    });
  };

  return (
    <div css={container}>
      <button
        className={`sorting-item ${
          sorting === Story_Sorting.DailyRankAsc && 'active'
        }`}
        onClick={() => handleSortingItemClick(Story_Sorting.DailyRankAsc)}
        role="radio"
      >
        {t('home_screen_label_sort_popular')}
      </button>
      <div className="divider" />
      <button
        className={`sorting-item ${
          sorting === Story_Sorting.PublishedDesc && 'active'
        }`}
        onClick={() => handleSortingItemClick(Story_Sorting.PublishedDesc)}
        role="radio"
      >
        {t('home_screen_title_sort_recent')}
      </button>
    </div>
  );
};

const container = css`
  display: flex;
  align-items: center;
  height: 1.125rem;

  .sorting-item {
    color: ${theme.colors.gray500};
    font: ${theme.font.body.body1};
    cursor: pointer;
  }

  .sorting-item.active {
    color: ${theme.colors.gray800};
    font: ${theme.font.header.subhead1};
  }

  .divider {
    margin: 0 0.5rem;
    width: 0.0625rem;
    height: 0.75rem;
    background: ${theme.colors.gray400};
  }
`;

export default SortingSelectionWrapper;
