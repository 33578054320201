import React, { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { NetworkStatus } from '@common/values';
import { WeekDayTab } from '@customTypes/story';
import { SPLoading } from '@components/commons';
import useInfiniteScroll from '@hooks/useInfiniteScroll';
import { ListWeekdayStoryQuery } from '@operations/queries/home/__generated__/useWeekDayStory.generated';
import { getWeekDaySectionName } from '@common/utils';

import StoryGrid from '../../../common/StoryGrid';
import WeekDayStoryItem from '../item/WeekDayStoryItem';
import { Story_Play_Type } from '@/baseType';

interface IWeekDayStoryGrid {
  data?: ListWeekdayStoryQuery;
  fetchMore: (options: { variables: { page: number } }) => void;
  hasNext: boolean;
  networkStatus: number;
  nextPage: number;
  playType?: Story_Play_Type;
}

const WeekDayStoryGrid = ({
  data,
  fetchMore,
  networkStatus,
  hasNext,
  nextPage,
  playType,
}: IWeekDayStoryGrid) => {
  const router = useRouter();
  const fetchMoreRef = useRef<HTMLDivElement>(null);
  const { week } = router.query;

  const intersecting = useInfiniteScroll(fetchMoreRef);

  const fetchNextPage = () => {
    if (!data) return;

    fetchMore({
      variables: {
        page: nextPage,
        ...(data?.listStoryByWebV3?.version && {
          version: data?.listStoryByWebV3?.version,
        }),
      },
    });
  };

  useEffect(() => {
    if (intersecting && hasNext) {
      fetchNextPage();
    }
  }, [intersecting, hasNext]);

  return (
    <StoryGrid section={getWeekDaySectionName(week as WeekDayTab)}>
      <>
        {data?.listStoryByWebV3.list.map((item, index) => (
          <WeekDayStoryItem
            index={index}
            key={item.storyId}
            hasTitle
            data={item}
            playType={playType}
          />
        ))}
        {networkStatus === NetworkStatus.loading && <SPLoading />}
        <div ref={fetchMoreRef} />
      </>
    </StoryGrid>
  );
};

export default WeekDayStoryGrid;
