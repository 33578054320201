import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BannerDtoFragment = { __typename?: 'BundleBanner', bundleId?: number | null, bundleBannerId: number, title?: string | null, content?: string | null, storyId?: number | null, link?: string | null, linkType?: Types.Banner_Link_Type | null, iOSFallbackLink?: string | null, aOSFallbackLink?: string | null, bannerImageFile?: { __typename?: 'File', link: string } | null, story?: { __typename?: 'Story', storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, playType: Types.Story_Play_Type, introImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null } | null };

export type GetAllBundleBannersQueryVariables = Types.Exact<{
  location: Types.Banner_Location;
}>;


export type GetAllBundleBannersQuery = { __typename?: 'Query', getAllBundleBannersByWeb: Array<{ __typename?: 'BundleBanner', bundleId?: number | null, bundleBannerId: number, title?: string | null, content?: string | null, storyId?: number | null, link?: string | null, linkType?: Types.Banner_Link_Type | null, iOSFallbackLink?: string | null, aOSFallbackLink?: string | null, bannerImageFile?: { __typename?: 'File', link: string } | null, story?: { __typename?: 'Story', storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, playType: Types.Story_Play_Type, introImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null } | null }> };

export const BannerDtoFragmentDoc = gql`
    fragment bannerDto on BundleBanner {
  bundleId
  bundleBannerId
  bannerImageFile {
    link
  }
  title
  content
  storyId
  link
  linkType
  iOSFallbackLink
  aOSFallbackLink
  story {
    storyId
    name
    authorName
    onHiatus
    isFinished
    isNew
    isUp
    playType
    introImageFile {
      link
    }
    storyWeekdays {
      weekday
    }
    genreKey {
      text {
        key
      }
    }
  }
}
    `;
export const GetAllBundleBannersDocument = gql`
    query GetAllBundleBanners($location: BANNER_LOCATION!) {
  getAllBundleBannersByWeb(data: {location: $location}) {
    ...bannerDto
  }
}
    ${BannerDtoFragmentDoc}`;

/**
 * __useGetAllBundleBannersQuery__
 *
 * To run a query within a React component, call `useGetAllBundleBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBundleBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBundleBannersQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGetAllBundleBannersQuery(baseOptions: Apollo.QueryHookOptions<GetAllBundleBannersQuery, GetAllBundleBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBundleBannersQuery, GetAllBundleBannersQueryVariables>(GetAllBundleBannersDocument, options);
      }
export function useGetAllBundleBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBundleBannersQuery, GetAllBundleBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBundleBannersQuery, GetAllBundleBannersQueryVariables>(GetAllBundleBannersDocument, options);
        }
export type GetAllBundleBannersQueryHookResult = ReturnType<typeof useGetAllBundleBannersQuery>;
export type GetAllBundleBannersLazyQueryHookResult = ReturnType<typeof useGetAllBundleBannersLazyQuery>;
export type GetAllBundleBannersQueryResult = Apollo.QueryResult<GetAllBundleBannersQuery, GetAllBundleBannersQueryVariables>;