import React from 'react';
import { StoryTitlePlaceHolderImage } from 'public/assets';
import { css } from '@emotion/react';

const StoryItemPlaceHolder = () => {
  return (
    <div css={wrapper}>
      <div className="image-wrapper"></div>
      <StoryTitlePlaceHolderImage width={112} height={25} />
    </div>
  );
};

const FIVE_ITEMS_MAX_WIDTH = '800px';
const FOUR_ITEMS_MAX_WIDTH = '610px';
const THREE_ITEMS_MAX_WIDTH = '490px';

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .image-wrapper {
    background-image: url('/assets/img_empty.png');
    background-size: 7rem 11.875rem;
    width: 7rem;
    height: 11.875rem;
  }

  // 30px: 양 옆 마진
  @media screen and (max-width: ${FIVE_ITEMS_MAX_WIDTH}) {
    .image-wrapper {
      background-size: calc((100vw - 30px) / 5) calc(((100vw - 30px) / 5) * 1.5);
      width: calc((100vw - 30px) / 5);
      height: calc(((100vw - 30px) / 5) * 1.5);
    }
  }

  @media screen and (max-width: ${FOUR_ITEMS_MAX_WIDTH}) {
    .image-wrapper {
      background-size: calc((100vw - 30px) / 4) calc(((100vw - 30px) / 4) * 1.5);
      width: calc((100vw - 30px) / 4);
      height: calc(((100vw - 30px) / 4) * 1.5);
    }
  }

  @media screen and (max-width: ${THREE_ITEMS_MAX_WIDTH}) {
    .image-wrapper {
      background-size: calc((100vw - 30px) / 3) calc(((100vw - 30px) / 3) * 1.5);
      width: calc((100vw - 30px) / 3);
      height: calc(((100vw - 30px) / 3) * 1.5);
    }
  }
`;

export default StoryItemPlaceHolder;
