import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';

const LabelNew = () => {
  const { t } = useTranslation();
  return (
    <div css={wrapper}>
      <div className="band">{t('home_screen_title_week_new')}</div>
    </div>
  );
};

const wrapper = css`
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-right: 0.25rem;
  .band {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.25rem;
    padding: 0.2rem 0.25rem;
    background: ${theme.colors.red500};
    color: ${theme.colors.white};
    font-weight: 800;
    font-size: 0.5625rem;
    border-radius: 0.125rem;
  }
`;

export default LabelNew;
