import React from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { AdultLabelIcon } from 'public/assets';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { getStatusText, getLabelText } from '@common/utils';
import { WeekdayStoryItemFragment } from '@operations/queries/home/__generated__/useWeekDayStory.generated';
import {
  Challenge_Story_Genre,
  Story_Sorting,
  Story_Play_Type,
} from '@/baseType';
import { useAppDispatch } from '@store/hooks';
import {
  setCurrentStoryLabelText,
  setWeekdayFilter,
  setWeekdaySorting,
  setPreviousSection,
} from '@slices/eventSlice';
import { getWeekDaySectionName } from '@common/utils';
import { HOME_TAB_TYPE } from '@customTypes/home';
import { WeekDayTab } from '@customTypes/story';

import WeekDayStoryItemTitle from '@/components/main/home/weekday/item/WeekDayStoryItemTitle';
import { LabelNew } from '@/components/main/home/weekday/label';
import SPImage from '@components/commons/image/SPImage';

import StoryItemLink from '../../../common/storyItem/link/StoryItemLink';
import BandLabel from '@/components/main/common/label/BandLabel';

interface IWeekDayStoryItem {
  index: number;
  data: WeekdayStoryItemFragment;
  hasTitle?: boolean;
  playType?: Story_Play_Type; // 여기서 오는 playType은 url에있는 playType
}

const WeekDayStoryItem = ({
  data,
  hasTitle,
  index,
  playType,
}: IWeekDayStoryItem) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { week, genre, sorting } = router.query;
  const dispatch = useAppDispatch();

  const setEventParams = () => {
    dispatch(
      setCurrentStoryLabelText(
        getLabelText({
          currentHomeTab: HOME_TAB_TYPE.WeekDay,
          currentWeekTab: week as WeekDayTab,
          isFinished: data.isFinished,
          isOnHiatus: data.onHiatus,
          isNew: data.isNew,
          isUp: data.isUp,
          weekdays: data.storyWeekdays,
        }),
      ),
    );
    dispatch(setWeekdayFilter(genre as Challenge_Story_Genre | 'TOTAL'));
    dispatch(setWeekdaySorting(sorting as Story_Sorting));
    dispatch(setPreviousSection(getWeekDaySectionName(week as WeekDayTab)));
  };

  return (
    <div
      css={wrapper}
      data-name={data.name}
      data-index={index}
      data-id={data.storyId}
      data-label={getLabelText({
        currentHomeTab: HOME_TAB_TYPE.WeekDay,
        currentWeekTab: week as WeekDayTab,
        isFinished: data.isFinished,
        isOnHiatus: data.onHiatus,
        isNew: data.isNew,
        isUp: data.isUp,
        weekdays: data.storyWeekdays,
      })}
      data-status={getStatusText(data)}
      data-filter={genre}
      data-content-type={
        data.playType === Story_Play_Type.Epub ? 'novel' : 'interactive'
      }
    >
      <StoryItemLink
        storyId={data.storyId}
        isAdult={data.isAdult}
        setEventParams={setEventParams}
      >
        <div className="thumbnail">
          <SPImage
            src={data.mainImageFile?.link || ''}
            layout="fill"
            alt={data.name}
          />
          {data.isAdult && (
            <div className="adult-sign">
              <AdultLabelIcon width={24} height={24} />
            </div>
          )}
          {data.isTimeLeapFree && (
            <div className="event-wrapper">
              <div className="event">타임리프 무료이벤트</div>
            </div>
          )}

          <div className="labelContainer">
            {playType !== Story_Play_Type.Interactive &&
              data.playType === Story_Play_Type.Interactive && (
                <BandLabel text={t('common_screen_title_interactive')} />
              )}
            {/* 요일탭에서 신작 라벨 보여주기 */}
            {week !== 'New' && week !== 'Finished' && data?.isNew && (
              <LabelNew />
            )}
          </div>
        </div>
        <>
          {hasTitle && (
            <WeekDayStoryItemTitle
              title={data?.name}
              author={data?.authorName ?? ''}
              genre={t(data?.genreKey?.text.key ?? '')}
              isShowUp={!data?.isFinished && data?.isUp}
              isPause={!data?.isFinished && data?.onHiatus}
            />
          )}
        </>
      </StoryItemLink>
    </div>
  );
};

const FIVE_ITEMS_MAX_WIDTH = '800px';
const FOUR_ITEMS_MAX_WIDTH = '610px';
const THREE_ITEMS_MAX_WIDTH = '490px';

const wrapper = css`
  display: flex;
  flex-direction: column;
  width: 7.8125rem;
  text-align: left;
  cursor: pointer;

  .thumbnail {
    position: relative;
    width: 7.8125rem;
    height: 11.875rem;
    border: 0.0625rem solid ${theme.colors.gray200};
    border-radius: 0.22rem;

    img {
      border-radius: 0.22rem;
      image-rendering: auto;
    }

    .adult-sign {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
    }
  }

  .weekday-list {
    right: 0.25rem;
    display: flex;
    gap: 0.125rem;
    flex-wrap: wrap;
  }

  .event-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 0.25rem;
    height: 100%;
  }

  .event {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.375rem 0.25rem 0.25rem;
    background: ${theme.colors.primary500};
    color: ${theme.colors.white};
    font-weight: 600;
    font-size: 0.5625rem;
    letter-spacing: -0.0313rem;
    border-radius: 0.125rem;
  }

  // 30px: 양 옆 마진
  @media screen and (max-width: ${FIVE_ITEMS_MAX_WIDTH}) {
    width: 100%;

    .thumbnail {
      width: calc((100vw - 30px) / 5);
      height: calc(((100vw - 30px) / 5) * 1.5);
    }
  }

  @media screen and (max-width: ${FOUR_ITEMS_MAX_WIDTH}) {
    width: 100%;

    .thumbnail {
      width: calc((100vw - 30px) / 4);
      height: calc(((100vw - 30px) / 4) * 1.5);
    }
  }

  @media screen and (max-width: ${THREE_ITEMS_MAX_WIDTH}) {
    width: 100%;

    .thumbnail {
      width: calc((100vw - 30px) / 3);
      height: calc(((100vw - 30px) / 3) * 1.5);
    }
  }

  .labelContainer {
    display: flex;
    align-items: start;

    padding: 0.25rem;
  }
`;

export default WeekDayStoryItem;
