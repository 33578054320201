import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { Portal } from '@components/commons';

interface IPopupContainer {
  children: JSX.Element | JSX.Element[];
  onClose?: () => void;
}

const PopupContainer = ({ children, onClose }: IPopupContainer) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Portal>
      <div css={container}>
        <div
          className="bg"
          onClick={() => {
            onClose && onClose();
          }}
        />
        {children}
      </div>
    </Portal>
  );
};

const container = css`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 29;

  .bg {
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.gray900};
    opacity: 0.6;
  }
`;

export default PopupContainer;
