import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import { useDirectAdsBanner } from '@operations/queries/directAdsBanner/useDirectAdsBanner';
import { checkDevice, openOldDeepLink } from '@common/utils';
import { DirectAdsBanner } from '@components/commons';
import { adSalesImpressionDirect, adSalesClickDirect } from '@lib/eventManager';

import { Direct_Ads_Type } from '@/baseType';
import { AdLocationType, Section } from '@/customTypes/event';
import Skeleton from 'react-loading-skeleton';

interface IDirectAdsBannerWrapper {
  type: Direct_Ads_Type;
  section: Section;
  location: AdLocationType;
}

const DirectAdsBannerWrapper = ({
  type,
  section,
  location,
}: IDirectAdsBannerWrapper) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isShow, setIsShow] = useState<boolean>(false);
  const { data: bannerData, loading: bannerLoading } = useDirectAdsBanner({
    type,
  });

  useEffect(() => {
    if (bannerData?.getDirectAds && checkDevice() !== 'PC') {
      const { directAdsId, title } = bannerData.getDirectAds;
      adSalesImpressionDirect({
        directAdsId,
        adTitle: title,
        adUrl: bannerData?.getDirectAds?.link ?? '',
        adLoc: location,
        adImageUrl: bannerData.getDirectAds.imageFile.link,
      });
    }
  }, [bannerData, location]);

  const onClickBanner = () => {
    if (!bannerData?.getDirectAds) return;
    openOldDeepLink({
      t,
      router,
      deepLink: bannerData.getDirectAds.link ?? '',
      iosStoreLink: bannerData.getDirectAds.iOSFallbackLink ?? '',
      aosStoreLink: bannerData.getDirectAds.aOSFallbackLink ?? '',
    });

    adSalesClickDirect({
      directAdsId: bannerData.getDirectAds.directAdsId,
      adTitle: bannerData.getDirectAds.title,
      adUrl: bannerData.getDirectAds.link ?? '',
      adLoc: location,
      adImageUrl: bannerData.getDirectAds.imageFile.link,
    });
  };

  useEffect(() => {
    setIsShow(checkDevice() !== 'PC');
  }, []);

  if (!isShow) return <></>;

  return bannerLoading ? (
    <div css={wrapper({ hasMargin: section !== '작품상세' })}>
      <Skeleton width="100%" height="54px" />
    </div>
  ) : (
    <>
      {bannerData?.getDirectAds ? (
        <div css={wrapper({ hasMargin: section !== '작품상세' })}>
          <DirectAdsBanner
            src={bannerData?.getDirectAds?.imageFile.link ?? ''}
            onClickBanner={onClickBanner}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const wrapper = ({ hasMargin }: { hasMargin: boolean }) => css`
  ${hasMargin && `margin: 1rem;`}
`;
export default DirectAdsBannerWrapper;
