import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { useGetRedirectUrlToStoryDetail } from '@hooks/useGetRedirectUrlToStoryDetail';
import { BookIcon, PopupXIcon } from 'public/assets';
import useTranslation from 'next-translate/useTranslation';

import {
  viewHomeContinueBanner,
  touchHomeContinueBanner,
  touchHomeContinueBannerOff,
} from '@/lib/eventManager';

export const RedirectUrlToStoryDetailRow = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const { redirectUrlToStoryDetail, onResetRedirectUrlToStoryDetail } =
    useGetRedirectUrlToStoryDetail();

  useEffect(() => {
    viewHomeContinueBanner();
  }, []);

  if (!redirectUrlToStoryDetail) {
    return null;
  }

  return (
    <div
      css={container}
      onClick={() => {
        onResetRedirectUrlToStoryDetail();
        router.push(redirectUrlToStoryDetail);
        touchHomeContinueBanner();
      }}
    >
      <BookIcon />
      <span>{t('common_popup_description_want_continue_reading')}</span>
      <PopupXIcon
        className={'close'}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          onResetRedirectUrlToStoryDetail();
          touchHomeContinueBannerOff();
        }}
      />
    </div>
  );
};

const container = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 3.9375rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 2.5rem;
  background-color: rgba(117, 59, 189, 0.7);
  z-index: 1;
  cursor: pointer;

  @media screen and (max-width: ${theme.devices.mobile}) {
    top: 0;
  }

  span {
    font: ${theme.font.body.body2};
    font-weight: bold;
    color: ${theme.colors.white};
    margin-left: 0.5rem;
  }

  .close {
    position: relative;
    top: -1px;
    margin-left: auto;
  }
`;
