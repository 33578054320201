import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import { GenreInfoDtoFragmentDoc } from './useNovelStoryGenreList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryGenreListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StoryGenreListQuery = { __typename?: 'Query', homeStoryGenresV3: Array<{ __typename?: 'StoryGenre', type: string, text: { __typename?: 'LocalizeKey', key: string, ko_KR: string }, title?: { __typename?: 'LocalizeKey', key: string, ko_KR: string } | null }> };


export const StoryGenreListDocument = gql`
    query StoryGenreList {
  homeStoryGenresV3 {
    ...GenreInfoDto
  }
}
    ${GenreInfoDtoFragmentDoc}`;

/**
 * __useStoryGenreListQuery__
 *
 * To run a query within a React component, call `useStoryGenreListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryGenreListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryGenreListQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoryGenreListQuery(baseOptions?: Apollo.QueryHookOptions<StoryGenreListQuery, StoryGenreListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryGenreListQuery, StoryGenreListQueryVariables>(StoryGenreListDocument, options);
      }
export function useStoryGenreListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryGenreListQuery, StoryGenreListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryGenreListQuery, StoryGenreListQueryVariables>(StoryGenreListDocument, options);
        }
export type StoryGenreListQueryHookResult = ReturnType<typeof useStoryGenreListQuery>;
export type StoryGenreListLazyQueryHookResult = ReturnType<typeof useStoryGenreListLazyQuery>;
export type StoryGenreListQueryResult = Apollo.QueryResult<StoryGenreListQuery, StoryGenreListQueryVariables>;