import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenreInfoDtoFragment = { __typename?: 'StoryGenre', type: string, text: { __typename?: 'LocalizeKey', key: string, ko_KR: string }, title?: { __typename?: 'LocalizeKey', key: string, ko_KR: string } | null };

export type EpubStoryGenresQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EpubStoryGenresQuery = { __typename?: 'Query', epubStoryGenresV3: Array<{ __typename?: 'StoryGenre', type: string, text: { __typename?: 'LocalizeKey', key: string, ko_KR: string }, title?: { __typename?: 'LocalizeKey', key: string, ko_KR: string } | null }> };

export const GenreInfoDtoFragmentDoc = gql`
    fragment GenreInfoDto on StoryGenre {
  type
  text {
    key
    ko_KR
  }
  title {
    key
    ko_KR
  }
}
    `;
export const EpubStoryGenresDocument = gql`
    query epubStoryGenres {
  epubStoryGenresV3 {
    type
    text {
      key
      ko_KR
    }
    title {
      key
      ko_KR
    }
  }
}
    `;

/**
 * __useEpubStoryGenresQuery__
 *
 * To run a query within a React component, call `useEpubStoryGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useEpubStoryGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEpubStoryGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useEpubStoryGenresQuery(baseOptions?: Apollo.QueryHookOptions<EpubStoryGenresQuery, EpubStoryGenresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EpubStoryGenresQuery, EpubStoryGenresQueryVariables>(EpubStoryGenresDocument, options);
      }
export function useEpubStoryGenresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EpubStoryGenresQuery, EpubStoryGenresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EpubStoryGenresQuery, EpubStoryGenresQueryVariables>(EpubStoryGenresDocument, options);
        }
export type EpubStoryGenresQueryHookResult = ReturnType<typeof useEpubStoryGenresQuery>;
export type EpubStoryGenresLazyQueryHookResult = ReturnType<typeof useEpubStoryGenresLazyQuery>;
export type EpubStoryGenresQueryResult = Apollo.QueryResult<EpubStoryGenresQuery, EpubStoryGenresQueryVariables>;