import React, { useRef } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { Section } from '@customTypes/event';
import { useVisibleStoryItemEvent } from '@/hooks/useVisibleStoryItemEvent';

interface IStoryGrid {
  section?: Section;
  children: JSX.Element[] | JSX.Element;
}

const StoryGrid = ({ section, children }: IStoryGrid) => {
  const storyListRef = useRef<HTMLDivElement | null>(null);

  useVisibleStoryItemEvent({ storyListRef, section });

  return (
    <main css={container}>
      <div className="story-grid" ref={storyListRef}>
        {children}
      </div>
    </main>
  );
};

const FIVE_ITEMS_MAX_WIDTH = '800px';
const FOUR_ITEMS_MAX_WIDTH = '610px';
const THREE_ITEMS_MAX_WIDTH = '490px';

const container = css`
  width: 100%;
  max-width: ${theme.maxWidth};
  display: flex;
  align-items: center;

  .story-grid {
    width: 100%;
    padding: 1rem 0.9375rem 1.25rem;
    background: ${theme.colors.white};
    display: grid;
    justify-content: start;
    grid-template-columns: repeat(auto-fit, 7.8125rem);
    column-gap: 0.25rem;
    row-gap: 1rem;

    // 30px: 양 옆 마진
    @media screen and (max-width: ${FIVE_ITEMS_MAX_WIDTH}) {
      grid-template-columns: repeat(5, minmax(0, calc((100vw - 30px) / 5)));
      justify-content: center;
    }

    @media screen and (max-width: ${FOUR_ITEMS_MAX_WIDTH}) {
      grid-template-columns: repeat(4, minmax(0, calc((100vw - 30px) / 4)));
      justify-content: center;
    }

    @media screen and (max-width: ${THREE_ITEMS_MAX_WIDTH}) {
      grid-template-columns: repeat(3, minmax(0, calc((100vw - 30px) / 3)));
      justify-content: center;
    }
  }
`;

export default StoryGrid;
