import React from 'react';
import { useRouter } from 'next/router';
import { touchHomeGenreTab } from '@lib/eventManager';
import { HOME_TAB_TYPE } from '@customTypes/home';
import { Story_Sorting } from '@/baseType';
import { getCurrentWeekType } from '@/common/utils';
import { css } from '@emotion/react';
import theme from '@styles/theme';

import { Tabs } from '@components/commons';
import { HomeStoryGenreItemFragment } from '@/operations/queries/home/__generated__/useHomeStoryGenres.generated';

const HomeTab = ({ tabs }: { tabs: HomeStoryGenreItemFragment[] }) => {
  const router = useRouter();
  const { tab = HOME_TAB_TYPE.Home } = router.query;

  return (
    <div css={wrapper}>
      <Tabs
        data={tabs}
        currentTab={(tab as string) ?? 'HOME'}
        onClickTabItem={(selectedTab) => {
          touchHomeGenreTab({
            section: tabs.find((tab) => tab.type === selectedTab)?.text
              .ko_KR as string,
          });

          if (router.pathname.includes('/interactive')) {
            router.push({
              pathname: '/interactive',
              query: {
                tab: selectedTab,
                ...(selectedTab === 'WEEK_DAY' && {
                  week: getCurrentWeekType(),
                  sorting: Story_Sorting.DailyRankAsc,
                  genre: 'TOTAL',
                }),
              },
            });
            return;
          }

          if (router.pathname.includes('/webnovel')) {
            router.push({
              pathname: '/webnovel',
              query: {
                tab: selectedTab,
                ...(selectedTab === 'WEEK_DAY' && {
                  week: getCurrentWeekType(),
                  sorting: Story_Sorting.DailyRankAsc,
                  genre: 'TOTAL',
                }),
              },
            });
            return;
          }

          router.push({
            pathname: '/',
            query: {
              tab: selectedTab,
              ...(selectedTab === 'WEEK_DAY' && {
                week: getCurrentWeekType(),
                sorting: Story_Sorting.DailyRankAsc,
                genre: 'TOTAL',
              }),
            },
          });
        }}
      />
    </div>
  );
};

const wrapper = css`
  position: sticky;
  top: 3.9375rem;
  z-index: 2;

  @media screen and (max-width: ${theme.devices.mobile}) {
    top: 2.6875rem;
  }
`;

export default HomeTab;
