import React, { useEffect, lazy } from 'react';
import { Story_Play_Type } from '@/baseType';
import { viewHomeTab } from '@lib/eventManager';
import { MonthlyStory, NewStory } from '@components/main/home/homeStory';
import { LayoutForLazyLoading } from '@/components/commons';
import PlayTypeProvider from '@/provider/PlayTypeProvider';
import { HOME_SECTION_TITLES } from '@/customTypes/home';
import useTranslation from 'next-translate/useTranslation';
import WebNovelContestWinnerStory from './homeStory/UGCContestWinnerStory';

const LazyRankingStory = lazy(
  () => import('@components/main/home/homeStory/RankingStory'),
);
const LazyPlayedStory = lazy(
  () => import('@components/main/home/homeStory/PlayedStory'),
);
const LazyGenreStoryList = lazy(
  () => import('@components/main/home/homeStory/GenreStoryList'),
);
const LazyInteractiveGenreStoryList = lazy(
  () => import('@components/main/home/homeStory/InteractiveGenreStoryList'),
);
const LazyNovelGenreStoryList = lazy(
  () => import('@components/main/home/homeStory/NovelGenreStoryList'),
);
const LazyFavoriteStory = lazy(
  () => import('@components/main/home/homeStory/FavoriteStory'),
);
const LazyFeaturedStoryItem = lazy(
  () => import('@components/main/home/homeStory/FeaturedStoryItem'),
);
const LazyGenerationStoryList = lazy(
  () => import('@components/main/home/homeStory/GenerationStoryList'),
);
const LazyOriginalStory = lazy(
  () => import('@components/main/home/homeStory/OriginalStory'),
);
const LazyFinishedStory = lazy(
  () => import('@components/main/home/homeStory/FinishedStory'),
);
const LazyAdultStory = lazy(
  () => import('@components/main/home/homeStory/AdultStory'),
);

interface IHomeContainer {
  playType?: Story_Play_Type;
}

const HomeContainer = ({ playType }: IHomeContainer) => {
  const { t } = useTranslation();

  useEffect(() => {
    viewHomeTab();
  }, []);

  if (playType === Story_Play_Type.Interactive) {
    // 인터랙티브 홈화면
    return (
      <PlayTypeProvider playType={Story_Play_Type.Interactive}>
        {/* 실시간 TOP 10 스토리 */}
        <LayoutForLazyLoading>
          <MonthlyStory
            title={t(HOME_SECTION_TITLES.Interactive.Monthly.Title)}
          />
        </LayoutForLazyLoading>
        {/* 스플 오리지널 작품 */}
        <LayoutForLazyLoading>
          <LazyOriginalStory />
        </LayoutForLazyLoading>
        {/* 이번 주 급 상승 작품 */}
        <LayoutForLazyLoading>
          <LazyRankingStory />
        </LayoutForLazyLoading>
        {/* 후방주의 */}
        <LayoutForLazyLoading>
          <LazyAdultStory />
        </LayoutForLazyLoading>
        {/* 완결 스토리 */}
        <LayoutForLazyLoading>
          <LazyFinishedStory
            title={t(HOME_SECTION_TITLES.Interactive.Finished.Title)}
          />
        </LayoutForLazyLoading>
        {/* 신작 스토리 */}
        <LayoutForLazyLoading>
          <NewStory title={t(HOME_SECTION_TITLES.Interactive.New.Title)} />
        </LayoutForLazyLoading>
        {/* 장르별 스토리 리스트 */}
        <LayoutForLazyLoading>
          <LazyInteractiveGenreStoryList />
        </LayoutForLazyLoading>
      </PlayTypeProvider>
    );
  } else if (playType === Story_Play_Type.Epub) {
    // 웹소설 홈화면
    return (
      <PlayTypeProvider playType={Story_Play_Type.Epub}>
        {/* WWW 웹소설 공모전 수상작 */}
        <LayoutForLazyLoading>
          <WebNovelContestWinnerStory />
        </LayoutForLazyLoading>
        {/* 이번 주 급 상승 작품 */}
        <LayoutForLazyLoading>
          <LazyRankingStory />
        </LayoutForLazyLoading>
        {/* 후방주의 */}
        <LayoutForLazyLoading>
          <LazyAdultStory />
        </LayoutForLazyLoading>
        {/* 완결 스토리 */}
        <LayoutForLazyLoading>
          <LazyFinishedStory
            title={t(HOME_SECTION_TITLES.WebNovel.Finished.Title)}
          />
        </LayoutForLazyLoading>
        {/* 신작 스토리 */}
        <LayoutForLazyLoading>
          <NewStory title={t(HOME_SECTION_TITLES.WebNovel.New.Title)} />
        </LayoutForLazyLoading>
        {/* 장르별 스토리 리스트 */}
        <LayoutForLazyLoading>
          <LazyNovelGenreStoryList />
        </LayoutForLazyLoading>
      </PlayTypeProvider>
    );
  } else {
    // 전체 홈화면
    return (
      <PlayTypeProvider>
        {/* 실시간 TOP 10 스토리 */}
        <LayoutForLazyLoading>
          <MonthlyStory title={t(HOME_SECTION_TITLES.Home.Monthly.Title)} />
        </LayoutForLazyLoading>
        {/* 읽고 있는 스토리 */}
        <LayoutForLazyLoading>
          <LazyPlayedStory />
        </LayoutForLazyLoading>
        {/* 스플 오리지널 작품 */}
        <LayoutForLazyLoading>
          <LazyOriginalStory />
        </LayoutForLazyLoading>
        {/* 이번 주 급 상승 작품 */}
        <LayoutForLazyLoading>
          <LazyRankingStory />
        </LayoutForLazyLoading>
        {/* 후방주의 */}
        <LayoutForLazyLoading>
          <LazyAdultStory />
        </LayoutForLazyLoading>
        {/* 좋아한 스토리 */}
        <LayoutForLazyLoading>
          <LazyFavoriteStory />
        </LayoutForLazyLoading>
        {/* 웹소설 공모전 수상작 */}
        <LayoutForLazyLoading>
          <WebNovelContestWinnerStory />
        </LayoutForLazyLoading>
        {/* 연령별 추천 스토리 */}
        <LayoutForLazyLoading>
          <LazyGenerationStoryList />
        </LayoutForLazyLoading>
        {/* 완결 스토리 */}
        <LayoutForLazyLoading>
          <LazyFinishedStory
            title={t(HOME_SECTION_TITLES.Home.Finished.Title)}
          />
        </LayoutForLazyLoading>
        {/* 신작 스토리 */}
        <LayoutForLazyLoading>
          <NewStory title={t(HOME_SECTION_TITLES.Home.New.Title)} />
        </LayoutForLazyLoading>
        {/* 피쳐드 섹션 */}
        <LayoutForLazyLoading>
          <LazyFeaturedStoryItem />
        </LayoutForLazyLoading>
        {/* 장르별 스토리 리스트 */}
        <LayoutForLazyLoading>
          <LazyGenreStoryList />
        </LayoutForLazyLoading>
      </PlayTypeProvider>
    );
  }
};

export default HomeContainer;
