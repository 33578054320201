import { useEffect, useState } from 'react';
import { STORAGE_KEY } from '@common/values';

// 로컬스토리지에 저장되어 있는 redirect url 값이 /story/ 로 시작하는 경우에 path 를 저장합니다.
// path 가 있으면 홈, 인터랙티브, 웹소설 탭의 배너의 상단에 띠지를 보여줍니다.
// 클릭하여 이동되거나, close 버튼을 누르면 해당 상태와 함께 로컬 스토리지의 데이터를 삭제합니다.
export const useGetRedirectUrlToStoryDetail = () => {
  const [redirectUrlToStoryDetail, setRedirectUrlToStoryDetail] = useState<
    string | null
  >(null);

  useEffect(() => {
    const redirectInfo = localStorage.getItem(STORAGE_KEY.REDIRECT_INFO);
    const parsedRedirectInfo: { redirectUrl: string; createdAt: Date } | null =
      redirectInfo ? JSON.parse(redirectInfo) : null;

    if (parsedRedirectInfo?.redirectUrl.includes('undefined')) {
      setRedirectUrlToStoryDetail(null);
      return;
    }

    const redirectUrl = parsedRedirectInfo?.redirectUrl;
    const isStoryDetailUrl = !!redirectUrl?.startsWith('/story/');

    if (isStoryDetailUrl && redirectUrl) {
      setRedirectUrlToStoryDetail(redirectUrl);
    }
  }, []);

  const onResetRedirectUrlToStoryDetail = () => {
    setRedirectUrlToStoryDetail(null);
    localStorage.removeItem(STORAGE_KEY.REDIRECT_INFO);
  };

  return {
    redirectUrlToStoryDetail,
    onResetRedirectUrlToStoryDetail,
  };
};
