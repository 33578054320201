import React from 'react';
import Image from 'next/image';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import placeholder from 'public/assets/placeholder_home_banner.png';
import { useRouter } from 'next/router';

const EmptyHomeBannerItem = () => {
  const router = useRouter();

  return (
    <div
      css={container({
        isHomeTab: !(
          router.pathname.includes('/interactive') ||
          router.pathname.includes('/webnovel')
        ),
      })}
    >
      <Image
        src={placeholder.src}
        objectFit="cover"
        layout="fill"
        priority={true}
      />
    </div>
  );
};

const container = ({ isHomeTab }: { isHomeTab: boolean }) => css`
  position: relative;
  padding-bottom: ${isHomeTab ? '70%' : '56.25%'};

  @media screen and (max-width: ${theme.devices.mobile}) {
    padding-bottom: ${isHomeTab ? '100%' : '56.25%'};
  }
`;

export default EmptyHomeBannerItem;
