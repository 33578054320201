import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { ArrowDownIcon } from 'public/assets';
import { checkDevice, insertCommas } from '@common/utils';

import GenreSelectionBottomSheet from './GenreSelectionBottomSheet';
import GenreSelectionPopup from './GenreSelectionPopup';
import { useRouter } from 'next/router';
import { Genre } from '@customTypes/story';
import { Story_Play_Type } from '@/baseType';
import { useGenreList } from '@/hooks/useGenreList';

interface IGenreSelectionWrapper {
  count: number;
}

const GenreSelectionWrapper = ({ count }: IGenreSelectionWrapper) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { genre } = router.query;
  const [isGenreSelectionBottomSheetOpen, setIsGenreSelectionBottomSheetOpen] =
    useState(false);
  const [isGenreSelectionPopupOpen, setIsGenreSelectionPopupOpen] =
    useState(false);
  const [currentGenreText, setCurrentGenreText] = useState<any>({
    showFilter: true,
    type: 'TOTAL',
    text: {
      ko_KR: '전체',
      key: 'ugc_screen_label_all',
    },
  });

  const handleGenreSelectionTitleClick = () => {
    checkDevice() === 'PC'
      ? setIsGenreSelectionPopupOpen(true)
      : setIsGenreSelectionBottomSheetOpen(true);
  };

  // const { data } = useQuery<ChallengeStoryGenre>(CHALLENGE_STORY_GENRE);
  const playType = router.pathname.includes('/webnovel')
    ? Story_Play_Type.Epub
    : router.pathname.includes('/interactive')
    ? Story_Play_Type.Interactive
    : undefined;

  const genreList = useGenreList(playType);
  useEffect(() => {
    if (!genreList) return;

    const allType: Genre = {
      showFilter: true,
      type: 'TOTAL',
      text: {
        ko_KR: '전체',
        key: 'ugc_screen_label_all',
      },
    };
    const genreTypeList = [allType, ...genreList];
    const currentGenreType = genreTypeList.find((item) => item.type === genre);

    setCurrentGenreText(currentGenreType || allType);
  }, [genre, genreList]);

  return (
    <>
      <div css={container} onClick={handleGenreSelectionTitleClick}>
        <div className="title">
          {t(currentGenreText?.text.key ?? '')}{' '}
          {count > 0 && <span>({insertCommas(count)})</span>}
        </div>
        <ArrowDownIcon className="icon" fill={theme.colors.gray800} />
      </div>
      <GenreSelectionBottomSheet
        isShow={isGenreSelectionBottomSheetOpen}
        onClose={() => setIsGenreSelectionBottomSheetOpen(false)}
      />
      <GenreSelectionPopup
        isShow={isGenreSelectionPopupOpen}
        onClose={() => setIsGenreSelectionPopupOpen(false)}
      />
    </>
  );
};

const container = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.gray800};
  cursor: pointer;
  position: relative;

  .title {
    height: 1rem;
    font: ${theme.font.header.subhead1};
  }

  .icon {
    margin-left: 0.125rem;
  }
`;

export default GenreSelectionWrapper;
