import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import WeekDayTabs from '@/components/main/home/weekday/tab/WeekTabs';
import { useWeekDayStoryQuery } from '@operations/queries/home/useWeekDayStory';
import { WeekDayTab } from '@customTypes/story';
import {
  Ui_Story_Section_Type,
  Story_Weekday,
  Story_Sorting,
  Direct_Ads_Type,
  Story_Play_Type,
} from '@/baseType';

import { STORAGE_KEY } from '@common/values';

import WeekDayStoryGrid from './grid/WeekDayStoryGrid';
import ListOptions from './tab/ListOptions';
import DirectAdsBannerWrapper from '../../common/banner/DirectAdsBannerWrapper';
import WeekDayStorySkeletonGrid from './grid/WeekDayStorySkeletonGrid';
import EmptyWeekdayStory from './item/EmptyWeekdayStory';
import theme from '@/styles/theme';

const DEFAULT_PAGE_COUNT = 15;

const WeekDayContainer = () => {
  const router = useRouter();
  const { week, genre, sorting } = router.query;
  const playType = router.pathname.includes('/webnovel')
    ? Story_Play_Type.Epub
    : router.pathname.includes('/interactive')
    ? Story_Play_Type.Interactive
    : undefined; // undefined면 home화면
  const { data, hasNext, fetchMore, networkStatus, loading } =
    useWeekDayStoryQuery({
      page: 1,
      pageSize: DEFAULT_PAGE_COUNT,
      ...((week as WeekDayTab) === 'New' && {
        uiSectionType: 'New' as Ui_Story_Section_Type,
      }),
      ...((week as WeekDayTab) === 'Finished' && {
        uiSectionType: 'Finished' as Ui_Story_Section_Type,
      }),
      ...(genre !== 'TOTAL' && { genre: genre as string }),
      ...(sorting && { sorting: sorting as Story_Sorting }),
      ...(Object.keys(Story_Weekday).indexOf(week as string) !== -1 && {
        weekday: week as Story_Weekday,
      }),
      // 요일탭에서는 인터랙티브 작품만 노출
      playType,
    });
  useEffect(() => {
    const rawScrollPosition =
      sessionStorage.getItem(STORAGE_KEY.HOME.SCROLL_POSITION) || '0';
    const scroll = Number(rawScrollPosition);
    if (playType === undefined) {
      window.scrollTo({
        top: scroll,
      });
    }

    sessionStorage.removeItem(STORAGE_KEY.HOME.SCROLL_POSITION);
  }, [week, genre, sorting]);

  return (
    <div css={container}>
      <div className="sticky-wrapper">
        <WeekDayTabs />
      </div>
      {loading ? (
        <WeekDayStorySkeletonGrid />
      ) : (
        <>
          {data && (
            <ListOptions storyCount={data.listStoryByWebV3.totalCount} />
          )}
          {data && data.listStoryByWebV3.list.length > 0 ? (
            <>
              <WeekDayStoryGrid
                data={data}
                fetchMore={fetchMore}
                hasNext={!!hasNext}
                nextPage={Number(data?.listStoryByWebV3.page) + 1}
                networkStatus={networkStatus}
                playType={playType}
              />
              <DirectAdsBannerWrapper
                type={Direct_Ads_Type.Home}
                section="홈"
                location={
                  playType === Story_Play_Type.Interactive
                    ? '인터랙티브탭-요일_배너'
                    : playType === Story_Play_Type.Epub
                    ? '웹소설탭-요일_배너'
                    : '홈탭-요일_배너'
                }
              />
            </>
          ) : (
            <>
              <DirectAdsBannerWrapper
                type={Direct_Ads_Type.Home}
                section="홈"
                location={
                  playType === Story_Play_Type.Interactive
                    ? '인터랙티브탭-요일_배너'
                    : playType === Story_Play_Type.Epub
                    ? '웹소설탭-요일_배너'
                    : '홈탭-요일_배너'
                }
              />
              <EmptyWeekdayStory />
            </>
          )}
        </>
      )}
    </div>
  );
};

const container = css`
  .sticky-wrapper {
    position: sticky;
    top: 6.6875rem;
    z-index: 1;

    @media screen and (max-width: ${theme.devices.mobile}) {
      top: 5.4rem;
    }
  }
`;

export default WeekDayContainer;
