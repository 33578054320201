import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { WeekDayTabList } from '@customTypes/story';
import { touchWeekdayTab } from '@lib/eventManager';
import { getHomeWeekdayTabText, getWeekDaySectionName } from '@common/utils';
import {
  STORAGE_KEY,
  WEEK_DAY_TAB_LIST,
  HOME_TAB_SCROLL_POSITION,
} from '@common/values';

const WeekDayTabs = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { week } = router.query;
  const tabListRef = useRef<HTMLUListElement>(null);

  const handleTabItemClick = (tab: WeekDayTabList) => {
    touchWeekdayTab({ section: getWeekDaySectionName(tab.type) });

    if (router.pathname.includes('/webnovel')) {
      router.replace({
        pathname: '/webnovel',
        query: { ...router.query, week: tab.type },
      });
      return;
    }

    if (router.pathname.includes('/interactive')) {
      router.replace({
        pathname: '/interactive',
        query: { ...router.query, week: tab.type },
      });
      return;
    }

    sessionStorage.setItem(
      STORAGE_KEY.HOME.SCROLL_POSITION,
      `${HOME_TAB_SCROLL_POSITION}`,
    );
    router.push({
      pathname: '/',
      query: { ...router.query, week: tab.type },
    });
  };

  return (
    <ul css={container} ref={tabListRef}>
      {WEEK_DAY_TAB_LIST.map((tab) => {
        return (
          <li
            key={tab.type}
            className={`tab-item ${week === tab.type && 'active'}`}
            onClick={() => handleTabItemClick(tab)}
          >
            {getHomeWeekdayTabText({ t, type: tab.textKey })}
          </li>
        );
      })}
    </ul>
  );
};

const container = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.625rem;
  padding: 0 0.875rem;
  border-bottom: 0.0625rem solid ${theme.colors.gray200};
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  gap: 0.75rem;
  background: ${theme.colors.white};

  ::-webkit-scrollbar {
    display: none;
  }

  .tab-item {
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.gray600};
    font: ${theme.font.body.body1};
    cursor: pointer;
  }

  .tab-item.active {
    background: ${theme.colors.black};
    color: ${theme.colors.white};
    border-radius: 50%;
    font: ${theme.font.header.subhead1};
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    justify-content: space-around;
  }
`;

export default WeekDayTabs;
