import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { NavCloseBtn } from 'public/assets';

import GenreList from '@/components/main/home/weekday/tab/GenreList';
import PopupContainerPortal from '@components/commons/popup/container/PopupContainerPortal';

interface IGenreSelectionPopup {
  isShow: boolean;
  onClose: () => void;
}

const GenreSelectionPopup = ({ isShow, onClose }: IGenreSelectionPopup) => {
  const { t } = useTranslation();

  if (!isShow) return null;

  return (
    <PopupContainerPortal>
      <div css={popupContainer}>
        <div className="header">
          <h3 className="title">{t('common_popup_title_select_genre')}</h3>
          <NavCloseBtn
            className="close-icon"
            onClick={onClose}
            width={24}
            height={24}
            fill={theme.colors.gray900}
          />
        </div>
        <GenreList onClose={onClose} contentHeight={404} />
      </div>
    </PopupContainerPortal>
  );
};

const popupContainer = css`
  position: absolute;
  width: 19.5rem;
  padding: 1.8125rem 1.25rem 0.8125rem;
  background: ${theme.colors.white};
  border-radius: 0.75rem;

  .close-icon {
    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8125rem;
    position: sticky;

    .title {
      color: ${theme.colors.gray900};
      font: ${theme.font.header.navigation};
    }
  }
`;

export default GenreSelectionPopup;
