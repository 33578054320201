import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';

import { HomeTabTitlePauseIcon, HomeTabTitleUpIcon } from 'public/assets';

interface IWeekDayStoryItemTitle {
  title?: string;
  genre?: string;
  author?: string;
  isShowUp?: boolean;
  isPause?: boolean;
}

const WeekDayStoryItemTitle = ({
  title,
  genre,
  author,
  isShowUp,
  isPause,
}: IWeekDayStoryItemTitle) => {
  return (
    <div css={container}>
      <div className="title-wrapper">
        <span>
          {isShowUp ? (
            <HomeTabTitleUpIcon />
          ) : (
            isPause && <HomeTabTitlePauseIcon />
          )}
        </span>
        <h2 className="title">{title}</h2>
      </div>
      <div className="genre">
        {genre && <span>{genre} ・ </span>} {author && <span>{author}</span>}
      </div>
    </div>
  );
};

const container = css`
  margin-top: 0.5rem;
  width: 100%;

  .title-wrapper {
    display: flex;

    svg {
      margin: 0 0.25rem 0.0375rem 0;
    }
  }

  /* 줄바꿈 문자 */
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    height: 1.25rem;
    white-space: nowrap;
    color: ${theme.colors.gray800};
    ${theme.font.header.subhead1}
  }

  .genre {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    height: 1.125rem;
    white-space: nowrap;
    overflow: hidden;
    ${theme.font.body.caption}
    color: ${theme.colors.gray500};
  }
`;

export default WeekDayStoryItemTitle;
