import React, { memo } from 'react';
import Link from 'next/link';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import {
  CrownIcon,
  AlarmIcon,
  StoryPlayLogoIcon,
  CoinIcon,
  IconSearch,
} from 'public/assets';
import theme from '@styles/theme';
import { GetUser } from '@customTypes/user';
import { GET_USER } from '@api/user';
import { STUDIO_LOGIN_URL } from '@common/values';
import {
  touchNotificationTab,
  touchSearchTab,
  touchStoreTab,
} from '@/lib/googleAnalytics';

interface IHomeHeader {
  tab: 'home' | 'interactive' | 'web-novel' | 'challenge';
}

const HomeHeader = ({ tab }: IHomeHeader) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { data: userData } = useQuery<GetUser>(GET_USER, {
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <header css={container}>
        {tab === 'home' && (
          <Link href="/">
            <a>
              <div className="logo-wrapper">
                <StoryPlayLogoIcon className="icon logo" />
              </div>
            </a>
          </Link>
        )}
        {tab === 'interactive' && (
          <Link href="/interactive">
            <a>
              <div className="logo-wrapper">
                <p className="icon logo">
                  {t('common_screen_title_interactive')}
                </p>
              </div>
            </a>
          </Link>
        )}
        {tab === 'web-novel' && (
          <Link href="/webnovel">
            <a>
              <div className="logo-wrapper">
                <p className="icon logo">{t('common_screen_title_webnovel')}</p>
              </div>
            </a>
          </Link>
        )}
        {tab === 'challenge' && (
          <Link href="/challenge">
            <a>
              <div className="logo-wrapper">
                <p className="icon logo">
                  {t('common_screen_title_challenge_story')}
                </p>
              </div>
            </a>
          </Link>
        )}
        <div className="right-wrapper">
          <button
            type="button"
            className="search-button"
            onClick={() => {
              router.push('/search');
              touchSearchTab();
            }}
          >
            <IconSearch />
          </button>
          {router.locale === 'ko' && (
            <a
              href={STUDIO_LOGIN_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="write-story-button">
                <CrownIcon className="crown-icon" />
                <span className="write-story-text">
                  {t('more_screen_title_write_story')}
                </span>
              </button>
            </a>
          )}
          {userData?.getUser.isAnonymous ? (
            <Link href="/auth">
              <a>
                <button className="login-button">
                  {t('common_screen_title_login')}
                </button>
              </a>
            </Link>
          ) : (
            <>
              {router.locale === 'ko' && (
                <Link
                  href={{
                    pathname: '/store',
                    query: { prevSection: 'home' },
                  }}
                >
                  <a
                    className="icon store"
                    onClick={() => {
                      touchStoreTab();
                    }}
                  >
                    <CoinIcon width="28" height="28" />
                  </a>
                </Link>
              )}
              <Link
                href={{
                  pathname: '/notification',
                  query: { prevSection: 'home' },
                }}
              >
                <a
                  className="icon alarm"
                  onClick={() => {
                    touchNotificationTab();
                  }}
                >
                  <AlarmIcon />
                </a>
              </Link>
            </>
          )}
        </div>
      </header>
    </>
  );
};

const container = css`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 0;
  min-height: 4rem;
  background: ${theme.colors.white};
  width: 100%;
  max-width: ${theme.maxWidth};
  align-items: center;
  z-index: 3;

  .logo-wrapper {
    display: flex;
    align-items: center;
  }

  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon.logo {
    color: ${theme.colors.gray900};
    ${theme.font.header.navigation}
  }

  .right-wrapper {
    display: flex;
    align-items: center;
  }

  .search-button {
    margin-right: 0.125rem;
    padding-top: 0.25rem;
    cursor: pointer;
  }

  .write-story-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.9375rem;
    height: 2rem;
    margin-left: 0.625rem;
    background: ${theme.colors.primary500};
    border-radius: 0.375rem;
    cursor: pointer;
  }

  .write-story-text {
    margin-left: 0.25rem;
    color: ${theme.colors.white};
    ${theme.font.header.subhead2};
  }

  .login-button {
    height: 2.25em;
    padding-left: 1rem;
    font: ${theme.font.body2Bold};
    color: ${theme.colors.primary500};
    cursor: pointer;
  }

  .icon.alarm {
    margin-left: 0.9131rem;
  }

  .icon.store {
    margin-left: 0.9131rem;
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    min-height: 2.75rem;
  }
`;

export default memo(HomeHeader);
