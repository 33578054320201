import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';

import GenreSelectionWrapper from './GenreSelectionWrapper';
import SortingSelectionWrapper from './SortingSelectionWrapper';

interface IListOptions {
  storyCount: number;
}

const ListOptions = ({ storyCount }: IListOptions) => {
  return (
    <div css={container}>
      <SortingSelectionWrapper />
      <GenreSelectionWrapper count={storyCount} />
    </div>
  );
};

const container = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 0.1875rem;
  transform: translate(0, 0.5625rem);

  .sorting {
    display: flex;
    align-items: center;
    height: 1.125rem;

    .sorting-item {
      color: ${theme.colors.gray800};
      font: ${theme.font.header.subhead1};
    }

    .divider {
      margin: 0 0.5rem;
      width: 0.0625rem;
      height: 0.75rem;
      background: ${theme.colors.gray400};
    }
  }
`;

export default ListOptions;
